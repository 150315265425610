import React from 'react'
import { ThemeProvider } from '@leshen/ui'
import siteTheme from '../../theme'
import 'typeface-poppins'
import GlobalStyles from './CustomStyles/GlobalStyles'

const Wrapper = ({ children }) => (
  <ThemeProvider theme={siteTheme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
)

export default Wrapper
